import type { Song } from '@ritmo/types'
import { Cover } from '@ritmo/ui'
import { SpeakerSimpleHigh } from 'phosphor-react'
import { HTMLAttributes } from 'react'
import useSWR from 'swr'
import { fetchTrackInfo } from '@ritmo/player-core'
import { offlineTracksDB } from '../../web-adapter/offlineTracksDB'

async function fetchTrackInfoItem(trackId: string) {
  try {
    const track = await offlineTracksDB.get<Song>(trackId)
    return track
  } catch (err) {
    try {
      const track = await fetchTrackInfo(trackId)
      return track
    } catch (err) {
      //
    }
  }
}

interface SongItemProps extends HTMLAttributes<HTMLDivElement> {
  trackId: string
  playing: boolean
}

export default function SongItem({ trackId, playing, ...rest }: SongItemProps) {
  const { data: track } = useSWR<Awaited<ReturnType<typeof fetchTrackInfoItem>>>(
    trackId,
    fetchTrackInfoItem
  )

  if (!track) return null

  return (
    <div
      className={`
        flex p-2 rounded-md items-center gap-2 my-2
        ${playing ? 'bg-slate-200' : 'hover:bg-slate-200'}
        cursor-pointer max-w-lg
      `}
      {...rest}
    >
      <div className="p-0.5 w-10 relative flex items-center justify-center">
        <Cover
          image={track?.image}
          decoding="async"
          loading="lazy"
          size="small"
          className={playing ? 'brightness-50' : ''}
        />
        {playing ? (
          <SpeakerSimpleHigh className="absolute text-white" weight="fill" size={20} />
        ) : null}
      </div>
      <div>
        <p className={`${playing ? 'text-accent' : ''} line-clamp-1`}>{track?.title}</p>
        <p className="text-gray-600 text-sm">{track?.artist}</p>
      </div>
    </div>
  )
}
