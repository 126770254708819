import { ArrowsClockwise, Warning } from 'phosphor-react'
import { Progress, Modal } from '@ritmo/ui'
import { getDownloadPercentage, getOverallStatus, getProfile } from '@ritmo/player-core'
import useSWR from 'swr'
import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { firstSyncCompleteState } from '../../atoms/sync'
import { Branch } from '@ritmo/types'

export default function WaitToSyncModal() {
  const setFirstSyncComplete = useSetRecoilState(firstSyncCompleteState)
  const { data: profile } = useSWR<Branch>('profile', getProfile, { refreshInterval: 2000 })

  const { data: overallStatus } = useSWR('overall-status', getOverallStatus, {
    refreshInterval: 1000,
    refreshWhenOffline: true,
  })

  const { data: overallProgress } = useSWR('overall-progress', getDownloadPercentage, {
    refreshInterval: 1000,
  })

  useEffect(() => {
    if (profile?.streamOnly) {
      return setFirstSyncComplete(true)
    }

    if (typeof overallProgress !== 'number') return
    if (overallProgress >= 10) {
      setFirstSyncComplete(true)
    }
  }, [overallProgress, setFirstSyncComplete, profile?.streamOnly])

  return (
    <Modal open className="w-10/12 max-w-md">
      <h4 className="mb-4">Descarga inicial</h4>
      <Progress animated max={10} value={overallProgress} />
      {overallStatus === 'no_internet' && (
        <p className="text-sm text-gray-500 mt-3 flex items-center gap-x-2">
          <ArrowsClockwise className="opacity-80 animate-spin" />
          Descarga pausada, esperando conexión a internet
        </p>
      )}
      {overallStatus === 'no_space' && (
        <p className="text-sm text-gray-500 mt-3 flex items-center gap-x-2">
          <Warning className="opacity-80 text-orange-600" />
          Espacio no suficiente para continuar descargando música
        </p>
      )}
    </Modal>
  )
}
