import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import {
  currentTrackState,
  playingState,
  timerState,
  willPlayOneShotState,
} from '../atoms/playback'
import { programmingState } from '../atoms/programming'
import { listeners } from '@ritmo/player-core'
import toast from 'react-hot-toast'

export const useCore = () => {
  const setProgrammingInfo = useSetRecoilState(programmingState)
  const setCurrentTrack = useSetRecoilState(currentTrackState)
  const setWillPlayOneShot = useSetRecoilState(willPlayOneShotState)

  const setPlaying = useSetRecoilState(playingState)
  const setTimer = useSetRecoilState(timerState)

  useEffect(() => {
    listeners.onProgrammingChange = setProgrammingInfo
    listeners.onCurrentTrackChange = (currentTrack) => {
      setCurrentTrack(currentTrack)
      if (!currentTrack.track) {
        setPlaying(false)
        setTimer({
          timer: '00:00',
          step: 0,
        })
      }
    }
    listeners.onWillPlayOneShotChange = (willPlay) => {
      if (willPlay) {
        toast.loading(`Reproduciendo anuncio en 10 segundos`, { duration: 10 * 1000 })
      }
      setWillPlayOneShot(willPlay)
    }
    listeners.onPlaybackStateChange = (playbackState) => {
      setPlaying(playbackState.playing)
      setTimer({
        timer: playbackState.timer,
        step: playbackState.step,
      })
    }
  }, [setProgrammingInfo, setCurrentTrack, setPlaying, setTimer, setWillPlayOneShot])
}
