import type { Song } from '@ritmo/types'
import { offline_tracks_db, rmUnderscore } from '@ritmo/player-core'
import { offlineTracksDB } from './offlineTracksDB'

offline_tracks_db.get = <M>(docId: string) => {
  return offlineTracksDB.get<M>(docId, { attachments: true })
}

offline_tracks_db.allDocsKeys = async () => {
  const { rows } = await offlineTracksDB.allDocs()
  return rows.map((row) => row.id)
}

offline_tracks_db.getFilePath = async (trackId: string, fileName: string) => {
  const blob = (await offlineTracksDB.getAttachment(trackId, rmUnderscore(fileName))) as Blob
  return URL.createObjectURL(blob)
}

offline_tracks_db.download = async (url: string, track: Song & { _rev?: string }) => {
  const r = await fetch(url)
  const blob = await r.blob()
  try {
    const savedDoc = await offlineTracksDB.get(track._id)
    track._rev = savedDoc._rev
  } catch (err) {
    delete track._rev
  }
  await offlineTracksDB.put({
    _attachments: {
      [rmUnderscore(track.originalname)]: {
        content_type: track.fileMime,
        data: blob,
      },
    },
    ...track,
    lastDownload: new Date().toString(),
  })
}

offline_tracks_db.getDocsCount = async () => {
  const { doc_count } = await offlineTracksDB.info()
  return doc_count
}

offline_tracks_db.remove = async (trackId) => {
  const track = await offlineTracksDB.get<Song>(trackId)
  const result = await offlineTracksDB.removeAttachment(
    track._id,
    rmUnderscore(track.originalname),
    track._rev
  )
  await offlineTracksDB.remove(track._id, result?.rev || track._rev)
}

offline_tracks_db.exists = async () => {
  try {
    await offlineTracksDB.info()
    return true
  } catch (err) {
    return false
  }
}
